<template>
  <v-card class="pa-3">
    <v-card-title style="display: flex;align-items: center;justify-content: space-between;">
      <span>
        <h2>{{ `${unidad.nombre} ${unidad.inicialesApellidos || unidad.apellidos} (${unidad.nombreCategoria}), ${new Date(fecha).toLocaleDateString('esp', { day:'numeric', month:'short', year:'numeric' }).toUpperCase()}` }}</h2>
      </span>
      <span>
        <v-btn color="error" @click.stop="$emit('close')">Cancelar</v-btn>
        <v-btn color="primary" @click.stop="guardar">Guardar</v-btn>
      </span>
    </v-card-title>
    <v-card-text class="pt-2">
      <v-row>
        <v-col v-for="(e,i) in evts" cols="12" md="6" :style="'background-color: ' + color(e.custom_key) + '33;border-top-left-radius: 10px;border-left:3px solid ' + color(e.custom_key)" class="mb-3" :key="i">
          <v-row>
            <v-col cols="12">
              <h3>{{ e.custom_key.replaceAll("_", " ") }}</h3>
              <AuxInput :firstcol="6" :lastcol="6" class="mx-2 no-gutters" title="Hº CAMAREROS Y LUGAR DE CITA">
                {{ JSON.parse(e.details)['Hº CAMAREROS Y LUGAR DE CITA'] }}
              </AuxInput>
              <AuxInput :firstcol="6" :lastcol="6" class="mx-2 no-gutters" title="MEDIOS TRANSPORTE">
                {{ JSON.parse(e.details)['MEDIOS TRANSPORTE'] }}
              </AuxInput>
              <AuxInput :firstcol="6" :lastcol="6" class="mx-2 no-gutters" title="UNIFORME CAMARERO">
                {{ JSON.parse(e.details)['UNIFORME CAMARERO'] }}
              </AuxInput>
            </v-col>
            <v-col cols="12">
              <v-textarea filled dense v-model="e.obsDireccion" placeholder="Observaciones"></v-textarea>
            </v-col>
            <v-col cols="12" md="4" style="background-color: white;border-right: 1px solid lightgray;" class="text-center">
              <v-btn plain icon :ripple="false" @click.stop="e.specialSchedule = (e.specialSchedule + 1) % 3" class="noma text-center mx-0 my-0"><v-icon :class="['noschedule','special','late'][e.specialSchedule]" style="transform: scale(1.5,1.5)">mdi-clock-alert</v-icon></v-btn>
            </v-col>
            <v-col cols="12" md="4" style="background-color: white;border-right: 1px solid lightgray; border-left: 1px solid lightgray;">
              <v-checkbox v-model="e.car" style="transform: scale(1.5,1.5)" hide-details="" :class="'noma text-center mx-5 my-0 ' + (e.car ? 'on' : 'off')" off-icon="mdi-car-off" on-icon="mdi-car"></v-checkbox>
            </v-col>
            <v-col cols="12" md="4" style="background-color: white;border-left: 1px solid lightgray;">
              <v-checkbox v-model="e.conf" style="transform: scale(1.5,1.5)" hide-details="" :class="'noma text-center mx-5 my-0 ' + (e.conf ? 'on' : 'off')" rows="3"></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    fecha: String,
    eventos: Array,
    empleado: Object
  },
  components: {
    AuxInput: () => import("@/components/AuxInput.vue"),
  },
  data() {
    return {
			unidad: {...this.empleado},
			evts: [...this.eventos],
    };
  },
  methods: {
    color(ck){
      if (ck.includes("Boda"))
        return "#b51442"
      if (ck.includes("Comunión"))
        return "#f77102"
      if (ck.includes("Evento"))
        return "#009e1d"
      if (ck.includes("Corporativo"))
        return "#2990ff"
      if (ck.includes("Pruebas Boda"))
        return "#f0be1a"
    },
    changeSchedule(emp){
      if (isNaN(emp.specialSchedule)) emp.specialSchedule = 1
      emp.specialSchedule = (emp.specialSchedule + 1) % 3
    },
		async guardar() {
      try{
        await axios({
          method: "post",
          url: `/planificacion/calendar`,
          data: this.evts
        }),
        this.$emit("saved")
        this.$root.$emit("snack", "Cambios guardados correctamente")
      }
      catch (e){
        this.$root.$emit("snack", "Error al guardar los cambios")
      }
		},
  },
  mounted() {
    this.eventos.forEach(e => {
      if (e.obs == "undefined") e.obs = "";
      if (e.obsDireccion == "undefined") e.obsDireccion = "";
    });
  },
};
</script>

<style>
.star:hover{
  cursor:pointer;
}
.star.v-btn--active::before {
  opacity: 0 !important;
}

.activent{
  max-height:50px !important;
  overflow: hidden
}
.active{
  max-height:1000px !important;
  overflow: hidden
}
.noschedule.v-icon{
  color: black !important;
}
.late.v-icon {
  color: red !important;
}
.special.v-icon {
  color: rgba(0, 255, 0, 1) !important;
}
.noma .v-input--selection-controls__input{
  margin: 0 !important;
}
.noma .v-input__slot{
  align-items: center !important;
  justify-content: center !important;
}
.noma.off ::before{
  color: black
}
.noma.on ::before{
  color: var(--v-primary-base)
}
</style>